<template>
    <div class="vip-content myOrder">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            我的订单
        </div>
        <div class="vip-content-inner">
            <div class="filter-form">
                <div class="item">
                    <div class="label">下单时间:</div>
                    <div class="con">
                        <el-date-picker
                            v-model="orderform.ordertime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="item">
                    <div class="label">订单号:</div>
                    <div class="con">
                        <el-input v-model="orderform.orderNumber" placeholder="请输入订单号"></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="label">产品编号:</div>
                    <div class="con">
                        <el-input v-model="orderform.goodsNumber" placeholder="请输入产品编号"></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="label">CAS号:</div>
                    <div class="con">
                        <el-input v-model="orderform.casNumber" placeholder="请输入CAS号"></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="label">付款状态:</div>
                    <div class="con">
                        <el-select placeholder="全部订单" v-model="orderform.payStatus">
                            <el-option
                                v-for="(item,index) in payStatuslist"
                                :key="item.value"
                                :label="item.lable"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="item">
                    <div class="label">收货人:</div>
                    <div class="con">
                        <el-input v-model="orderform.deliveryUsername" placeholder="请输入收货人"></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="label">订单状态:</div>
                    <div class="con">
                        <el-select placeholder="全部状态" v-model="orderform.status">
                            <el-option
                                v-for="(item,index) in orderstatuslist"
                                :key="item.value"
                                :label="item.lable"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="item">
                    <div class="g-btns">
                        <div class="g-btn small" @click="clicksecher">搜索</div>
                        <div class="g-btn small white" @click="clickclear">清空</div>
                    </div>
                </div>
            </div>
            <div class="g-table-wrap">
            <table class="order-table g-table" v-loading="loading">
                <thead>
                <tr>
                    <td>订单号</td>
                    <td>下单日期</td>
                    <td>收货人</td>
	                <td>订单金额</td>
	                <td>运费</td>
                    <td>订单状态</td>
                    <td>支付方式</td>
                    <td>付款状态</td>
                    <td>操作</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in myOrderList" :key="index">
                    <td>
                        <a :href="`#/goodsOrderDetail?id=${item.id}`">{{ item.orderNumber }}</a>
                    </td>
                    <td>{{ item.createTime }}</td>
                    <td>{{ item.deliveryUsername }}</td>
	                <td>{{ item.amount }}</td>
	                <td>{{ item.freight }}</td>
<!--                    订单状态：0处理中,1已确认,2待发货,3部分发货,4已发货,5部分收货,6已完成,7已取消,8退款中,9已退款-->
<!--                    <td>{{ item.status==0?'处理中':item.status==1?'已确认':item.status==2?'待发货':item.status==3?'部分发货':item.status==4?'已发货':item.status==5?'部分收货':item.status==6?'已完成':item.status==7?'已取消':item.status==8?'退款中':item.status==9?'已退款':'' }}</td>-->
<!--                    订单状态：0 待支付,1 已下单,2 待发货,3 部分发货,4 已发货,5 部分收货,6 已收货,7 已取消,8 退款中,9 已退款,10 已完成-->
<!--                    <td>{{ item.statusName==0?'待支付':item.statusName==1?'已下单':item.statusName==2?'待发货':item.statusName==3?'部分发货':item.statusName==4?'已发货':item.statusName==5?'部分收货':item.statusName==6?'已收货':item.statusName==7?'已取消':item.statusName==8?'退款中':item.statusName==9?'已退款':item.statusName==10?'已完成':'' }}</td>-->
                    <td>{{ item.statusName}}</td>
<!--                    支付方式：0对公，1微信，2支付宝，3余额-->
                    <td>{{ item.payType==0?'对公打款':item.payType==1?'微信支付':item.payType==2?'支付宝支付':item.payType==3?'余额支付':'账期' }}</td>
                    <td>{{ item.payStatus==0?'待支付':'已支付' }}</td>
                    <td>
                        <div class="tools">
                            <div class="btn" @click="jumpTo('/goodsOrderDetail',item.id)">查看订单</div>
                            <div class="btn" @click="jionCart(item)">再次下单</div>
                            <div class="btn" v-if="item.status!=0&&item.status!=1&&item.status!=2&&item.status!=7&&item.status!=8&&item.status!=9" @click="jumpTo('/goodsDeliveryInfo',item.id)">发货详情</div>
                            <div class="btn" @click="clickPrint(item)">打印合同</div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
                <div class="mat20 text_cen" v-if="myOrderList.length==0">暂无数据</div>
            </div>
            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="[10, 50, 100]"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {joincartSave, ordergoodslist, pageByCustomer} from "@/api/transaction";
import Bus from "@/utils/bus";
import {getToken} from "@/utils/auth";

export default {
    name: "orderForGoods",
    data() {
        return {
            loading:true, // 加载中
            orderform: {
                ordertime:"", // 下单时间
                orderNumber:"", // 订单编号
                goodsNumber:"", // 产品编号
                casNumber:"", // cas号
                payStatus:"", // 支付状态 0、1 未支付、已支付
                deliveryUsername:"", // 收货人
                status:"", // 订单状态
            },
            myOrderList:[],
            current:1,
            size:10,
            total:0,

            payStatuslist:[
                {
                    lable:'全部',
                    value:''
                },
                {
                    lable:'未付款',
                    value:0
                },
                {
                    lable:'已付款',
                    value:1
                }
            ], // 付款状态list
            orderstatuslist:[
                {
                    lable:'全部',
                    value:''
                },
                {
                    lable:'待支付',
                    value:0
                },
                {
                    lable:'已下单',
                    value:1
                },
                {
                    lable:'待发货',
                    value:2
                },
                {
                    lable:'部分发货',
                    value:3
                },
                {
                    lable:'已发货',
                    value:4
                },
                {
                    lable:'部分收货',
                    value:5
                },
                {
                    lable:'已收货',
                    value:6
                },
                {
                    lable:'已取消',
                    value:7
                },
                {
                    lable:'退款中',
                    value:8
                },
                {
                    lable:'已退款',
                    value:9
                },
                {
                    lable:'已完成',
                    value:10
                },
	            {
		            lable:'已关闭',
		            value:11
	            }

    ], // 订单状态list

        }
    },
    mounted() {
      this.getOrderList() // 订单商品列表
    },
    methods:{
        //点击打印合同
        clickPrint(item){
            // window.location.href = 'https://chn.yifanglab.com/api/blade-goods/goods-info/export-cart-template?blade-auth='+getToken();
            window.location.href = 'https://chn.yifanglab.com/api/blade-trade/order-info/order-contract?id='+item.id+'&blade-auth='+ getToken();

        },

        // 去订单详情 发货详情
        jumpTo:function (path,id) {
            this.$router.push({path:path,query: {id:id}}) //
        },
        // 点击搜索
        clicksecher(){
            this.myOrderList=[]
            this.current=1
            this.size=10
            this.getOrderList() // 订单商品列表
        },
        // 点击清空
        clickclear(){
            this.orderform= {
                ordertime:"", // 下单时间
                orderNumber:"", // 订单编号
                goodsNumber:"", // 产品编号
                casNumber:"", // cas号
                payStatus:"", // 支付状态 0、1 未支付、已支付
                deliveryUsername:"", // 收货人
                status:"", // 订单状态
            }
        },
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getOrderList();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getOrderList();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getOrderList();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getOrderList();
        },

    //    ----------------接口请求
        // 获取订单列表
        getOrderList(){
            let beginTime='',endTime='' // 开始时间 结束时间
            if(this.orderform.ordertime){
                beginTime=this.$utils.formatDate(this.orderform.ordertime[0],'yyyy-mm-dd') + ' 00:00:00'
                endTime=this.$utils.formatDate(this.orderform.ordertime[1],'yyyy-mm-dd') + ' 23:59:59'
            }
            let param={
                customerId:localStorage.getItem('customId'),
                type:0, // 0/1 线上订单 线下询价
                current:this.current,
                size:this.size,
                beginTime:beginTime, // 开始时间
                endTime:endTime, // 结束时间
                orderNumber:this.orderform.orderNumber, // 订单编号
                goodsNumber:this.orderform.goodsNumber, // 产品编号
                casNumber:this.orderform.casNumber, // cas号
                payStatus:this.orderform.payStatus, // 支付状态 0、1 未支付、已支付
                deliveryUsername:this.orderform.deliveryUsername, // 收货人
                status:this.orderform.status, // 订单状态

            }

            console.log(this.$utils.formatDate(this.orderform.ordertime[0],'yyyy-mm-dd'))
            console.log(this.$utils.formatDate(this.orderform.ordertime[1],'yyyy-mm-dd'))
            // console.log(param)

            pageByCustomer(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    console.log('订单列表',res.data.data)

                    res.data.data.records.forEach(i=>{
                        i.createTime=i.createTime.slice(0,10)
                    })
                    this.myOrderList=res.data.data.records
                    this.total=Number(res.data.data.total)
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            }).catch(err=>{
                this.loading=false
            })
        },
        // 再次下单
        jionCart(item){
            // 获取订单商品
            ordergoodslist({orderId:item.id}).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    // console.log('订单商品列表',res.data.data)
                    res.data.data.forEach((i,index)=>{
                        let param={
                            goodsId:i.goodsId,
                            customerId:localStorage.getItem('customId'),
                            count:i.quantityPurchase
                        }
                        // 加入购物车
                        joincartSave(param).then((res1) => {
                            if (res1.data.code==200) {
                                if(index+1==res.data.data.length){
                                    this.$message({
                                        type: 'success',
                                        message: "已加入购物车",
                                        duration: 2000
                                    });
                                    Bus.$emit('addCart', '加购物车')
                                }

                            }else{
                                // this.$message({
                                //     type: 'error',
                                //     message: res.data.message,
                                //     duration: 2000
                                // });
                            }
                        })
                    })
                    // this.ordergoodsList=res.data.data
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            })

        },


    }
}
</script>

<style lang="scss">
//.addressForGoods.vip-content{
//	.el-select-dropdown .el-popper{
.el-scrollbar .el-select-dropdown__wrap.el-scrollbar__wrap{
    margin-bottom: -15px !important;
    margin-right: -5px !important;
}
//}

//}
.myOrder{
	td{
		padding-left: 0!important;
	}
}

</style>
<style scoped>

</style>
